export enum AccessibilityHtmlTags {
  MainHeading = 'h1',
  SecondaryHeading = 'h2',
  Paragraph = 'p',
}

export type AccessibilityAttributes = {
  role?: string;
  'aria-labelledby'?: string;
  'aria-label'?: string;
};
