export enum DataHooks {
  TimeSlotsSelection = 'time-slots-selection',
  TimeSlotText = 'time-slot-text',
  ShowAllButton = 'show-all-button',
}

export enum TimeSlotsSelectionDisplayMode {
  Stretch = 'stretch',
  Column = 'column',
}
