import { ChevronDown, ChevronUp } from '@wix/wix-ui-icons-common/on-stage';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import React, { useState, useEffect, useRef } from 'react';
import {
  ActionsMenuLayout,
  MobileDrawer,
  Popover,
  PopoverTriggerAction,
  TextButton,
  TextButtonPriority,
} from 'wix-ui-tpa/cssVars';
import {
  WidgetComponents,
  WidgetElements,
} from '../../../../../utils/bi/consts';
import { useCalendarActions } from '../../../Hooks/useCalendarActions';
import {
  ServiceSelectionViewModel,
  ServiceSelection as ServiceSelectionType,
} from '../../../ViewModel/serviceSelectionViewModel/serviceSelectionViewModel';
import { ServiceSelectionDataHooks } from './constants';
import { classes, st } from './ServiceSelection.st.css';

export interface ServiceSelectionProps {
  viewModel: ServiceSelectionViewModel;
}

export const ServiceSelection: React.FC<ServiceSelectionProps> = ({
  viewModel,
}) => {
  const { isMobile, isRTL } = useEnvironment();
  const { onServiceChange, onElementClicked } = useCalendarActions();
  const [isOpen, setIsOpen] = useState(false);
  const popoverRef = useRef<any>(null);
  const ctaRef = useRef<TextButton>(null);
  const escPressedRef = useRef(false);

  useEffect(() => {
    if (!isMobile) {
      if (isOpen) {
        requestAnimationFrame(() => popoverRef.current?.focus());
      } else if (escPressedRef.current) {
        escPressedRef.current = false;
        ctaRef.current?.focus();
      }
    }
  }, [isOpen, isMobile]);

  const { ctaLabel, services } = viewModel;

  const setServiceOptionsVisibility = (isVisible: boolean) => {
    setIsOpen(isVisible);
  };

  const onEscPress = () => {
    escPressedRef.current = true;
    setServiceOptionsVisibility(false);
  };

  const onCtaClick = () => {
    setServiceOptionsVisibility(!isOpen);
    onElementClicked(
      WidgetComponents.CHANGE_SERVICE,
      WidgetElements.CHANGE_SERVICE_BUTTON,
    );
  };

  const onServiceClicked = (service: ServiceSelectionType) => {
    setIsOpen(false);
    onServiceChange(service.id, service.slug);
  };

  const cta = (
    <CTA isOpen={isOpen} label={ctaLabel} onClick={onCtaClick} ref={ctaRef} />
  );

  const content = (
    <Content
      isMobile={isMobile}
      onServiceClicked={onServiceClicked}
      services={services}
    />
  );

  return (
    <div className={st(classes.root, { isMobile })}>
      {isMobile ? (
        <>
          {cta}
          <MobileDrawer
            data-hook={ServiceSelectionDataHooks.MobileDrawer}
            isOpen={isOpen}
            onRequestClose={() => setServiceOptionsVisibility(false)}
          >
            {content}
          </MobileDrawer>
        </>
      ) : (
        <Popover
          // @ts-expect-error
          ref={popoverRef}
          tabIndex={-1}
          shown={isOpen}
          onEscPress={onEscPress}
          onTabOut={() => setServiceOptionsVisibility(false)}
          onClickOutside={() => setServiceOptionsVisibility(false)}
          data-hook={ServiceSelectionDataHooks.Popover}
          showArrow={false}
          className={classes.popover}
          placement={isRTL ? 'bottom-end' : 'bottom-start'}
          triggerAction={PopoverTriggerAction.click}
          aria-labelledby={ServiceSelectionDataHooks.CTA}
        >
          <Popover.Element>{cta}</Popover.Element>
          <Popover.Content>{content}</Popover.Content>
        </Popover>
      )}
    </div>
  );
};

const CTA = React.forwardRef<
  TextButton,
  {
    label: string;
    isOpen: boolean;
    onClick: () => void;
  }
>(({ isOpen, label, onClick }, ref) => (
  <TextButton
    id={ServiceSelectionDataHooks.CTA}
    aria-label={label}
    data-hook={ServiceSelectionDataHooks.CTA}
    aria-expanded={isOpen}
    priority={TextButtonPriority.primary}
    aria-haspopup="dialog"
    className={classes.cta}
    onClick={onClick}
    ref={ref}
    suffixIcon={isOpen ? <ChevronUp /> : <ChevronDown />}
  >
    {label}
  </TextButton>
));

const Content = ({
  isMobile,
  onServiceClicked,
  services,
}: {
  services: ServiceSelectionType[];
  isMobile: boolean;
  onServiceClicked: (service: ServiceSelectionType) => void;
}) => (
  <div
    data-hook={ServiceSelectionDataHooks.ServicesContainer}
    className={st(classes.contentContainer, { isMobile })}
  >
    <ActionsMenuLayout
      data-hook={ServiceSelectionDataHooks.ServicesActionMenu}
      className={classes.menu}
    >
      {services.map((service) => (
        <ActionsMenuLayout.Item
          key={service.id}
          content={service.name}
          subtitle={service.price}
          onClick={() => onServiceClicked(service)}
        />
      ))}
    </ActionsMenuLayout>
  </div>
);
