import React from 'react';
import { Spinner, SpinnerTypes } from 'wix-ui-tpa/cssVars';
import { WidgetLoaderDataHooks } from './constants';
import { useWidgetLoaderHeight } from './useWidgetLoaderHeight/useWidgetLoaderHeight';
import { classes } from './WidgetLoader.st.css';

export interface WidgetLoaderProps {
  spinnerType?: SpinnerTypes;
  withDatePickerPlaceholder?: boolean;
}

export const WidgetLoader: React.FC<WidgetLoaderProps> = ({
  spinnerType = SpinnerTypes.regular,
  withDatePickerPlaceholder,
}) => {
  const loaderHeight = useWidgetLoaderHeight({ withDatePickerPlaceholder });

  return (
    <div
      data-hook={WidgetLoaderDataHooks.ROOT}
      className={classes.root}
      style={{ minHeight: loaderHeight }}
    >
      <Spinner
        data-hook={WidgetLoaderDataHooks.SPINNER}
        diameter={50}
        className={classes.spinner}
        type={spinnerType}
      />
    </div>
  );
};
